import {Field as FormikField} from 'formik';
import React from 'react';

//@deprecated - make something better or just use Formik's Field
const LabeledField = (props: { label?: string, name?: string } | any) => {
    const baseField = <FormikField name={props.name} as={props.as} className=''></FormikField>;
    if (!props.label) {
        return baseField;
    }
    return (
        <label {...props}>
            {props.label}
            {baseField}
            {props.children}
        </label>
    );
};

export default LabeledField;
