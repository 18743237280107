import React from "react";
import './style.scss';

type Props = {
    url: string
};

class FileDownloader extends React.Component<Props, any> {
    private ref;

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<any>, snapshot?: any): void {
        this.ref.click();
    }

    componentDidMount(): void {
        this.ref.click();
    }

    render() {
        return (
            <a className='file-downloader' href={this.props.url} ref={a => this.ref = a}>-</a>
        );
    };
}

export default FileDownloader;
