import React from "react";
import './style.scss';
import Icon from "../Icon/Icon";

class FailSign extends React.Component<any, any> {

    render() {

        return (
            <Icon icon='fa-exclamation-triangle' className='fail-sign' title='Operacja nie powiodła się' />
        );
    }
}

export default FailSign;
