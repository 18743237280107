import React from "react";
import Class from "../../../model/Class";

type Props = {
    class: Class
};

export default class ClassInfo extends React.Component<Props, any> {
    render() {
        const codesCount = this.props.class.pupilCodes.length;
        return (
            <div className='level'>
                <div className='level-item'>{this.props.class.name}</div>
                <div className='level-item'>
                    <span className={codesCount ? '' : 'errors'}>({codesCount})</span>
                </div>
            </div>
        );
    }
}
