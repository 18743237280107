import PrivacyPolicy from "../model/PrivacyPolicy";
import ApiPromise from "../infrastructure/apiClient/ApiPromise";
import * as apiClient from "../infrastructure/apiClient/apiClient";

const getPrivacyPolicy = (): ApiPromise<PrivacyPolicy> => {
    return ApiPromise.fromAxiosPromise(
        apiClient.privacyPolicy.get()
    ).onSuccess(response => response.data);
}

export default getPrivacyPolicy;
