import React from 'react';
import './App.css';
import {Provider} from 'react-redux';
import Routing from "./Routing";
import {BrowserRouter} from "react-router-dom";
import './view/common/style/style.scss';
import AppContainer from "./AppContainer";

class App extends React.Component {
    render() {
        return (
            <div id='app'>
                <Provider store={AppContainer.getContainer().getReduxStore()}>
                    <BrowserRouter>
                        <Routing/>
                    </BrowserRouter>
                </Provider>
            </div>
        );
    }
}

export default App;

