import {combineReducers} from "redux";
import credentialsReducer from "./credentialsReducer";
import storageReducer from "./storageReducer";
import signedOutReducer from "./signOutReducer";
import serverErrorReducer from "./serverErrorReducer";

export const rootReducer = combineReducers({
    credentials: credentialsReducer,
    storage: storageReducer,
    signedOut: signedOutReducer,
    serverError: serverErrorReducer
});
