import React from "react";
import ListContest from "../../../model/ListContest";
import { Link } from "react-router-dom";
import { ROUTE } from "../../../routes";
import downloadRankingsFile from "../../../action/downloadRankingsFile";
import FileDownloader from "../../common/component/FileDownloader/FileDownloader";
import downloadStatisticsFile from "../../../action/downloadStatisticsFile";

type Props = {
    contest: ListContest
};

type State = {
    rankingsFileUrl?: string,
    statisticsFileUrl?: string,
};

class ContestItem extends React.Component<Props, State> {
    private f;

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className='contest-list-item columns'>
                <div className='column is-two-fifths'>
                    {this.props.contest.tenantName}
                </div>
                <div className='column'>
                    {this.props.contest.contestModeStart}
                </div>
                <div className='column'>
                    {this.props.contest.contestModeEnd}
                </div>
                <div className='column'>
                    <Link to={ROUTE.CONTEST__ID({contestId: this.props.contest.id})}>
                        Szczegóły
                    </Link>
                </div>
                <div className='column'>
                    <Link to={ROUTE.CONTEST__ID__PUPIL_CODES({contestId: this.props.contest.id})}>
                        Kody uczniów
                    </Link>
                </div>
                <div className='column'>
                    <span className='file-link' onClick={this.onRankingsFileDownloadButtonClick}>Pobierz rankingi</span>
                </div>
                <div className='column'>
                    <span className='file-link' onClick={this.onStatisticsFileDownloadButtonClick}>Pobierz statystyki</span>
                </div>
                {
                    this.state.rankingsFileUrl &&
                    <FileDownloader url={this.state.rankingsFileUrl}/>
                }
                {
                    this.state.statisticsFileUrl &&
                    <FileDownloader url={this.state.statisticsFileUrl}/>
                }
            </div>
        );
    }

    private onRankingsFileDownloadButtonClick = () => {
        downloadRankingsFile(this.props.contest.id)
            .onSuccess(body => {
                this.setState({
                    rankingsFileUrl: body.rankingsFileUrl + '?asAttachment=1'
                });
            })
    }

    private onStatisticsFileDownloadButtonClick = () => {
        downloadStatisticsFile(this.props.contest.id)
            .onSuccess(body => {
                this.setState({
                    statisticsFileUrl: body.statisticsFileUrl + '?asAttachment=1'
                });
            })
    }
}

export default ContestItem;
