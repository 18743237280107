import React from "react";
import ListContest from "../../../model/ListContest";
import ContestItem from "./ContestItem";
import {getManyContests} from "../../../action/getManyContests";
import isLoadingState from "../../common/state/isLoadingState";
import Loader, {DisplayMode} from "../../common/component/Loader/Loader";
import './style.scss'

type State = isLoadingState & {
    contests: Array<ListContest>
};

class ContestsPage extends React.Component<any, State> {
    constructor(props) {
        super(props);
        this.state = {
            contests: [],
            isLoading: true
        };
    }

    componentDidMount(): void {
        getManyContests()
            .onSuccess(contests => {
                this.setState({contests: contests})
            })
            .catch()
            .finally(() => {
                this.setState({isLoading: false})
            })
    }

    render() {
        if (this.state.isLoading) {
            return this.withLayout(<Loader displayMode={DisplayMode.fillParent}/>);
        }

        return this.withLayout(
            <div>
                <h1 className='title'>Lista konkursów</h1>
                <div id='contests-list'>
                    <ul>
                        <div className='contest-list-item-header columns'>
                            <div className='column is-two-fifths'>
                                <label>Klient</label>
                            </div>
                            <div className='column'>
                                <label>Rozpoczęcie TK</label>
                            </div>
                            <div className='column'>
                                <label>Zakończenie TK</label>
                            </div>
                            <div className='column'>

                            </div>
                            <div className='column'>

                            </div>
                            <div className='column'>

                            </div>
                        </div>
                        {
                            this.state.contests.map(contest => {
                                return <ContestItem key={contest.id} contest={contest}/>
                            })
                        }
                    </ul>
                </div>
            </div>
        );
    }

    private withLayout = (content) => {
        return (
            <div id="contests-page" className='page'>
                {content}
            </div>
        );
    }
}

export default ContestsPage;
