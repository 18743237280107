import storage from "redux-storage";
import {applyMiddleware, createStore} from "redux";
import thunk from "redux-thunk";
import createEngine from "redux-storage-engine-sessionstorage";
import {composeWithDevTools} from "redux-devtools-extension/logOnlyInProduction";
import {rootReducer} from "./reducer/rootReducer";
import {defaultAppState} from "./AppState";


const reducer = storage.reducer(rootReducer);
const engine = createEngine('pogromcy-smieci');

const middleware = storage.createMiddleware(engine);

const composeEnhancers = composeWithDevTools({});

export const store = createStore(reducer, defaultAppState, composeEnhancers(
    applyMiddleware(thunk, middleware)
));

const load = storage.createLoader(engine);
load(store);


