import * as apiClient from "../infrastructure/apiClient/apiClient";
import uuid from "../model/uuid";
import School from "../model/School";
import ApiPromise from "../infrastructure/apiClient/ApiPromise";

const submitSchool = (school: School): ApiPromise<uuid | void> => {
    if (school.id) {
        return ApiPromise.fromAxiosPromise(apiClient.schoolsId.put(school.id, school))
            .onSuccess(() => {
                return;
            });
    }

    return ApiPromise.fromAxiosPromise(apiClient.contestsIdSchools.post(school.contestId, school))
        .onSuccess((response: { data: { schoolId: uuid } }) => {
            return response.data.schoolId;
        });
};

export default submitSchool;
