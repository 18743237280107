import uuid from "../model/uuid";
import * as apiClient from '../infrastructure/apiClient/apiClient';
import ApiPromise from "../infrastructure/apiClient/ApiPromise";

const generateDocWithPupilCodesForContest = (contestsId: uuid, count: number): ApiPromise<void> => {
    return ApiPromise.fromAxiosPromise(apiClient.contestsId.patch(contestsId, {
        generatePupilCodes: {count},
        generatePupilCodesDocument: {}
    })).onSuccess(() => {
        return;
    });
};

export default generateDocWithPupilCodesForContest;
