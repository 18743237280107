import * as apiClient from "../infrastructure/apiClient/apiClient";
import uuid from "../model/uuid";
import Class from "../model/Class";
import ApiPromise from "../infrastructure/apiClient/ApiPromise";

const submitClass = (clazz: Class): ApiPromise<uuid | void> => {
    if (clazz.id) {
        return ApiPromise.fromAxiosPromise(apiClient.classesId.put(clazz.id, clazz))
            .onSuccess(() => {
                return;
            });
    }

    return ApiPromise.fromAxiosPromise(apiClient.schoolsIdClasses.post(clazz.schoolId, clazz))
        .onSuccess((response: { data: { classId: uuid } }) => {
            return response.data.classId;
        });
};

export default submitClass;
