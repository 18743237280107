import uuid from "../model/uuid";
import { AxiosResponse } from 'axios';

import * as apiClient from "../infrastructure/apiClient/apiClient";
import ApiPromise from "../infrastructure/apiClient/ApiPromise";

const downloadRankingsFile = (contestId: uuid): ApiPromise<{rankingsFileUrl: string}> => {
    return ApiPromise.fromAxiosPromise(
        apiClient.contestsId.patch(contestId, {generateRankingsDocument: {}})
    )
        .onSuccess((response: AxiosResponse) => response.data)
}

export default downloadRankingsFile;
