import deepFreeze from 'deep-freeze';

export const ACTION = deepFreeze({
    SIGN_OUT: 'SIGN_OUT',

    CREDENTIALS: {
        SAVE: 'CREDENTIALS__SAVE',
        DELETE: 'CREDENTIALS__DELETE'
    },

    SERVER_ERROR: {
        SHOW: 'SERVER_ERROR__SHOW',
        DISMISS: 'SERVER_ERROR__DISMISS',
    }
});
