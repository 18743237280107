const templateRoute = (template: string, params?: object) => {
    if (!params) {
        return template;
    }

    let route = '';
    for (let key in params) {
        const toReplace = ':' + key;
        route = template.replace(toReplace, params[key]);
    }

    return route;
};

export const ROUTE = Object.freeze({
    PRIVACY_POLICY: '/privacy-policy',
    CONTEST: '/contest',
    CONTEST__ID: (params?: {contestId: string}) => templateRoute('/contest/:contestId', params),
    CONTEST__ID__PUPIL_CODES: (params?: {contestId: string}) => templateRoute('/contest/:contestId/pupil-codes', params),
    CONTESTS: '/contests',
    SIGN_OUT: '/sign-out'
});
