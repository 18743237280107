import DifficultyLevel from "../../../../model/DifficultyLevel";
import {Field} from "formik";
import React from "react";
import mapDifficultyLevelToLabel from "../mapDifficultyLevelToLabel";

const ScoreThresholdsFormPart = () => {
    let counter = 0;

    return (
        <div className='difficulty-level-form-part container'>
            {Object.values(DifficultyLevel).map((level) => {
                const key = 'scoreThresholds[' + counter + ']';
                const placesCountName = key + '.scoreInPercent';
                const difficultyLevelName = key + '.difficultyLevel';
                counter++;
                return (
                    <div key={key} className='level field'>
                        <div className='level-left'>
                            <label>
                                {mapDifficultyLevelToLabel(level)}
                            </label>
                            <div className='field has-addons'>
                                <p className='control'>
                                    <Field required name={placesCountName} type='number' min={0} max={100} />
                                </p>
                                <div className="control">
                                    <span className="button is-static">%</span>
                                </div>
                                <Field name={difficultyLevelName} type='hidden'/>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    );
};

export default ScoreThresholdsFormPart;
