import React from "react";
import './style.scss';

export enum DisplayMode {
    default,
    fullscreen,
    fillParent
}

type Props = {
    displayMode?: DisplayMode | undefined,
};

class Loader extends React.Component<Props, any> {
    render() {
        const mainContent = this.mainContent();

        if (this.props.displayMode === DisplayMode.fullscreen) {
            return this.inFullScreenMode(mainContent);
        }

        if (this.props.displayMode === DisplayMode.fillParent) {
            return this.inFullParentMode(mainContent);
        }

        return mainContent;
    }

    private inFullScreenMode = (content) => {
        return (
            <div className='spinner-fullscreen'>
                {content}
            </div>
        );
    }

    private inFullParentMode = (content) => {
        return (
            <div className='spinner-fill-parent'>
                {content}
            </div>
        );
    }

    private mainContent = () => {
        return (
            <div className="spinner">
                <div className="spinner__step spinner__step-one"/>
                <div className="spinner__step spinner__step-two"/>
                <div className="spinner__step spinner__step-three"/>
                <div className="spinner__step spinner__step-four"/>
                <div className="spinner__step spinner__step-five"/>
            </div>
        );
    }
}

export default Loader;
