import * as apiClient from "../infrastructure/apiClient/apiClient";
import ListContest from "../model/ListContest";
import {normalizeContestForReading} from "../model/ContestWithSchoolsAndClasses";
import ApiPromise from "../infrastructure/apiClient/ApiPromise";

export const getManyContests = (): ApiPromise<ListContest[]> => {
    return ApiPromise.fromAxiosPromise(apiClient.contests.get())
        .onSuccess((response) => {
            return (response.data as Array<ListContest>)
                .map(contest => normalizeContestForReading(contest) as ListContest);
        });
};
