import axios from 'axios';
import {store} from "../../redux/store";
import AppState from "../../redux/AppState";
import appConfig from "../../../config";

const configuredAxios = () => {
    const configured = axios.create({
        baseURL: appConfig.apiHost + '/api/super-admin/web/1'
    });

    configured.interceptors
        .request
        .use(config => {
            let headers = config.headers;
            const state: AppState | undefined | null = store.getState();
            if (state && state.credentials) {
                const authToken = state.credentials.accessToken || null;
                if (authToken) {
                    headers['authorization'] = 'Bearer ' + authToken;
                }
            }

            return {
                ...config,
                headers
            }
        });
    
    return configured;
};


const ApiConnection = configuredAxios();


export default ApiConnection;
