import React from "react";
import uploadSponsorLogoFile from "../../../../action/uploadSponsorLogoFile";
import uuid from "../../../../model/uuid";
import placeholder from '../../../resource/sponsor-logo-placeholder.png';
import Loader, {DisplayMode} from "../../../common/component/Loader/Loader";
import isLoadingState from "../../../common/state/isLoadingState";

type State = isLoadingState & {
    selectedFile: any,
    isLoading: boolean,
    logoFileUrl: string | null | undefined,
    error?: string
};

type Props = {
    onLogoFileUploaded: (fileId: uuid) => void,
    onLogoFileRemoved: () => void,
    logoFileUrl: string | null | undefined
};

class SponsorLogoFileUploader extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            selectedFile: null,
            isLoading: false,
            logoFileUrl: this.props.logoFileUrl
        };
    }

    render() {
        return (
            <div className='columns loadable'>
                <div className="file column is-one-quarter">
                    <label className="file-cta button is-primary">
                        <input className='file-input' type="file" name="file" onChange={this.onChange}/>
                        <div className="file-icon">
                            <i className="fas fa-upload"></i>
                        </div>
                        <div className="file-label">
                            Dodaj logo sponsora
                        </div>

                    </label>
                    <span className='errors'>{this.state.error}</span>
                    {
                        this.state.logoFileUrl &&
                        <label className="file-cta button" onClick={this.onRemoveBtnClicked}>

                            <div className="file-label">
                                Usuń logo sponsora
                            </div>

                        </label>
                    }

                </div>

                <div id='sponsor-logo-preview' className='column is-three-quarters'>
                    {
                        this.state.isLoading &&
                        <Loader displayMode={DisplayMode.fillParent}/>
                    }
                    <img alt='logo sponsora' src={this.state.logoFileUrl || placeholder}/>
                </div>

            </div>
        )
    }

    onRemoveBtnClicked = () => {
        this.setState({
            logoFileUrl: undefined
        });
        this.props.onLogoFileRemoved();
    };

    onChange = (event) => {
        this.setState({error: undefined});
        if (event.target.files[0]) {
            const data = new FormData();
            data.append('file', event.target.files[0]);

            this.setState({
                isLoading: true
            });

            uploadSponsorLogoFile(data)
                .onSuccess(fileInfo => {
                    this.setState({
                        logoFileUrl: fileInfo.logoFileUrl
                    });
                    this.props.onLogoFileUploaded(fileInfo.fileId)
                })
                .onClientError(() => {
                    this.setState({
                        error: 'Plik niepoprawny'
                    })
                })
                .finally(() => {
                    this.setState({
                        isLoading: false
                    })
                })
        }

    }
}

export default SponsorLogoFileUploader;
