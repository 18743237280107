import React from "react";
import {ErrorMessage} from "formik";
import './style.scss';

type Props = {
    name: string
};

export default class FieldError extends React.Component<Props, any> {
    render() {
        return (
            <div className='form-field-error'>
                <span className='form-field-error--message'>
                    <ErrorMessage name={this.props.name}/>
                </span>
            </div>

        );
    }
}
