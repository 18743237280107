import React from "react";
import uuid from "../../../model/uuid";
import ContestWithSchoolsAndClasses from "../../../model/ContestWithSchoolsAndClasses";
import isLoadingState from "../../common/state/isLoadingState";
import Loader, {DisplayMode} from "../../common/component/Loader/Loader";
import SchoolWithClasses from "../../../model/SchoolWithClasses";
import generateDocWithPupilCodesForContest from "../../../action/generateCodesForPupilsInContest";
import {Field, Form, Formik} from 'formik';
import SchoolArea from "./SchoolArea";
import getContest from "../../../action/getContest";
import './style.scss';
import Icon from "../../common/component/Icon/Icon";


type FormData = {
    count: number
}

const initialValues: FormData = {
    count: 40
};

type Props = {
    contestId: uuid
};

type State = isLoadingState & {
    contest?: ContestWithSchoolsAndClasses,
    hasClassWithoutCodes: boolean
}

class PupilCodesPage extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            contest: undefined,
            hasClassWithoutCodes: false
        };
    }

    componentDidMount(): void {
        this.getContest();
    }

    render() {
        return this.withLayout(
            <div>
                {
                    this.state.isLoading &&
                    <Loader displayMode={DisplayMode.fillParent}/>
                }
                <h1 className='title'>KODY UCZNIÓW</h1>
                <strong>KLIENT</strong>: {this.state.contest?.tenantName || '...'}
                <div>
                    <Formik initialValues={initialValues} onSubmit={this.onSubmit}>
                        <Form id='pupil-codes-generate-form'>
                            <div className='field'>
                                <label>Liczba kodów</label>
                                <Field className='is-1' name='count' type='number' min={1}/>
                            </div>
                            <div>
                                <button type='submit' className='is-primary'>Generuj kody dla klas bez kodów</button>
                            </div>
                        </Form>
                    </Formik>
                </div>
                <div className='class-list'>
                    <h2 className='subtitle'>Klasy z liczbą wygenerowanych kodów</h2>
                    {
                        this.state.hasClassWithoutCodes &&
                        <div className='errors'>
                            <Icon icon='fa-exclamation-triangle'/>
                            <strong className='errors'>(Obecne są klasy bez kodów)</strong>
                        </div>
                    }
                    {
                        this.state.contest?.pupilCodesFileUrl &&
                        <a className='link' href={this.state.contest?.pupilCodesFileUrl + '?asAttachment=1'}>Pobierz listę kodów</a>
                    }
                    <div className='columns'>
                        {
                            this.state.contest?.schools.map((school: SchoolWithClasses) => {
                                return (
                                    <div key={school.id} className='column is-1'>
                                        <SchoolArea school={school}/>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }

    private withLayout = (content) => {
        return (
            <div id='pupil-codes-page' className='page'>
                {content}
            </div>
        );
    };

    onSubmit = (values: FormData) => {
        generateDocWithPupilCodesForContest(this.props.contestId, values.count)
            .onSuccess(() => {
                this.getContest()
            })
    };

    getContest = () => {
        getContest(this.props.contestId)
            .onSuccess((contest: ContestWithSchoolsAndClasses) => {
                this.setState({contest: contest});

                let hasClassWithoutCodes = false;

                for (let i in contest.schools) {
                    for (let j in contest.schools[i].classes) {
                        if (contest.schools[i].classes[j].pupilCodes.length === 0) {
                            hasClassWithoutCodes = true;
                            break;
                        }
                    }
                }
                this.setState({hasClassWithoutCodes})
            })
            .finally(() => {
                this.setState({isLoading: false})
            })
    }
}

export default PupilCodesPage;
