import React from "react";
import {NavLink} from "react-router-dom";
import './style.scss';
import logo from '../../../resource/logo.png';
import {ROUTE} from "../../../../routes";
import signOut from "../../../../action/signOut";

export default class NavBar extends React.Component<any, any> {
    render() {
        return (
            <nav id='nav-bar' className="navbar" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <a className="" href="/">
                        <img alt='logo' src={logo} />
                    </a>
                </div>

                <div className="navbar-menu">
                    <div className="navbar-start">
                        <NavLink to={ROUTE.CONTEST} className='navbar-item'>Nowy konkurs</NavLink>
                        <NavLink to={ROUTE.CONTESTS} className='navbar-item'>Lista konkursów</NavLink>
                        <NavLink to={ROUTE.PRIVACY_POLICY} className='navbar-item'>Polityka prywatności</NavLink>
                    </div>

                    <div className="navbar-end">
                        <div className="navbar-item">
                            <div className="buttons">
                                <button title='Wyloguj' className="button is-light" onClick={this.onSignOutClick}>
                                    <span className='icon'>
                                        <i className='fas fa-power-off'></i>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>

        );
    }

    private onSignOutClick = () => {
        signOut();
    }
}
