import React from "react";

type Props = {
    onDismissClick: () => void
};

export default class ServerErrorInfo extends React.Component<Props, any> {
    render() {
        return (
            <div id='server-error-info' className="notification is-danger global-notification">
                <button className="delete" onClick={this.props.onDismissClick}/>
                Błąd serwera lub serwer niedostępny lub brak połączenia z internetem.
            </div>
        );
    };

}
