import uuid from "../../../model/uuid";
import ApiConnection from "../ApiConnection";

export const get = (contestId: uuid) => {
    return ApiConnection.get('/contests/' + contestId + '/schools');
};

type PostBody = {
    name: string,
    shortName: string,
    address: string
};

export const post = (contestId: uuid, body: PostBody) => {
    return ApiConnection.post('/contests/' + contestId + '/schools', body);
};
