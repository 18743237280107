import DifficultyLevel from "./DifficultyLevel";
import uuid from "./uuid";

type Class = {
    id?: uuid
    schoolId: string
    difficultyLevel: DifficultyLevel,
    name: string,
    pupilCodes: Array<string>
};

export default Class;


export const createClassesFromText = (text: string, schoolId: string): Array<Class> => {
    const normalizeNumber = (number: string): string  => {
        const map = {
            I: 1,
            II: 2,
            III: 3,
            IIII: 4,
            IV: 4,
            V: 5,
            VI: 6,
            VII: 7,
            VIII: 8,
        };

        return String(map[number] || number);
    }

    const getLevel = (numberString: string): DifficultyLevel => {
        const number = Number(numberString);

        if (number < 3) {
            return DifficultyLevel.PRIMARY_1_2;
        }

        if (number < 6) {
            return DifficultyLevel.PRIMARY_3_5;
        }

        return DifficultyLevel.PRIMARY_6_8;
    }

    const fragments = text.toUpperCase().split(/[\n\r,;]+/);

    return fragments.map(fragment => {
        const matches = /^(?<numberPart>\d+|[iIvV]+)\s*(?<lettersPart>\w+)/g.exec(fragment.trim());
        const groups = matches?.groups;

        if (!groups?.numberPart) {
            return undefined;
        }

        const numberPart = normalizeNumber(groups.numberPart);
        const lettersPart = groups.lettersPart || '';

        if (!numberPart) {
            return undefined;
        }

        return {
            name: numberPart + lettersPart.toLowerCase(),
            difficultyLevel: getLevel(numberPart),
            schoolId: schoolId,
            pupilCodes: []
        } as Class;
    }).filter(cl => !!cl) as Array<Class>

}
