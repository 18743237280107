import React from "react";
import {Field, Form, Formik, FormikHelpers} from "formik";
import School from "../../../../model/School";
import LabeledField from "../../../common/form/LabeledField";
import submitSchool from "../../../../action/submitSchool";
import SchoolWithClasses from "../../../../model/SchoolWithClasses";
import FormState from "../../../common/state/FormState";
import asyncFormSubmitted from "../../../common/form/asyncFormSubmitted";
import FormikOnRender from "../../../common/form/FormikOnRender";
import SuccessSign from "../../../common/component/SuccessSign/SuccessSign";
import FailSign from "../../../common/component/FailSign/FailSign";
import Loader, {DisplayMode} from "../../../common/component/Loader/Loader";
import uuid from "../../../../model/uuid";
import * as Yup from "yup";
import FieldError from "../../../common/component/FieldError/FieldError";

type Props = {
    contestId: uuid,
    school?: School,
    onSchoolCreated?: (school: SchoolWithClasses) => void
};

type State = {
    formState: FormState
}

const validationSchema = Yup.object().shape({
    name: Yup
        .string()
        .trim()
        .min(1, 'Min. 1 znak'),

    shortName: Yup
        .string()
        .trim()
        .min(1, 'Min. 1 znak')
        .max(10, 'Max. 10 znaków'),

});


class SchoolForm extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            formState: new FormState()
        };
    }

    render() {
        const initialValues = {
            id: '',
            shortName: '',
            address: '',
            contestId: this.props.contestId,
            name: ''
        };

        return (
            <Formik validationSchema={validationSchema}
                    initialValues={this.props.school || initialValues}
                    onSubmit={this.onSubmit}>
                {(form: FormikOnRender) => (
                    <Form className='school-form loadable' onChange={this.onChange}>
                        {this.state.formState.isLoading &&
                        <Loader displayMode={DisplayMode.fillParent}/>
                        }

                        <Field type='hidden' name='id'/>
                        <Field type='hidden' name='contestId'/>
                        <LabeledField name='name' label='Pełna nazwa' className='field' required>
                            <FieldError name="name"/>
                        </LabeledField>
                        <LabeledField name='shortName' label='Skrócona nazwa' className='field' required>
                            <FieldError name="shortName"/>
                        </LabeledField>
                        <LabeledField name='address' label='Adres' className='field'/>
                        <div className='school-form--controls is-pulled-right'>
                            {
                                this.state.formState.hasSucceeded() &&
                                <SuccessSign/>
                            }
                            {
                                this.state.formState.hasFailed() &&
                                <FailSign/>
                            }
                            <button disabled={!(this.state.formState.canBeSubmitted() && form.dirty)}
                                    type='submit'
                                    className='is-primary'>
                                {this.props.school?.id
                                    ? 'Zapisz'
                                    : 'Dodaj szkołę'
                                }
                            </button>

                        </div>

                    </Form>
                )}
            </Formik>

        );
    }

    onSubmit = (values: School, helpers: FormikHelpers<any>) => {
        asyncFormSubmitted(this, () => {
            return submitSchool(values)
                .onSuccess((schoolId) => {
                    if (schoolId) {
                        helpers.resetForm();

                        const createdSchool: SchoolWithClasses = {
                            ...values,
                            id: schoolId,
                            classes: []
                        };

                        if (this.props.onSchoolCreated) {
                            this.props.onSchoolCreated(createdSchool);
                        }
                    }
                })
        });
    };

    onChange = () => {
        this.setState({
            formState: this.state.formState.changed()
        });
    }

}

export default SchoolForm;
