type AppState = {
    credentials: null | {
        accessToken?: string | null,
        refreshToken?: string | null
    },

    signedOut: boolean,

    storage: {
        loaded?: boolean | null
    },

    serverError: boolean
}

export default AppState;

export const defaultAppState: AppState = {
    credentials: {},
    storage: {
        loaded: false
    },
    signedOut: true,
    serverError: false
};
