import uuid from "../model/uuid";
import * as apiClient from "../infrastructure/apiClient/apiClient";
import ContestWithSchoolsAndClasses, {normalizeContestForReading} from "../model/ContestWithSchoolsAndClasses";
import ApiPromise from "../infrastructure/apiClient/ApiPromise";

const getContest = (contestId: uuid): ApiPromise<ContestWithSchoolsAndClasses> => {
    return ApiPromise.fromAxiosPromise(apiClient.contestsId.get(contestId))
        .onSuccess((response) => {
            return normalizeContestForReading(response.data) as ContestWithSchoolsAndClasses;
        });
};


export default getContest;
