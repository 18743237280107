import DifficultyLevel from "../../../../model/DifficultyLevel";
import React from "react";
import mapDifficultyLevelToLabel from "../mapDifficultyLevelToLabel";
import {Field} from "formik";

const AwardedPlacesCountsFormPart = () => {
    let counter = 0;

    return (
        <div className='difficulty-level-form-part container'>
            {Object.values(DifficultyLevel).map((level) => {
                const key = 'awardedPlacesCounts[' + counter + ']';
                const placesCountName = key + '.placesCount';
                const difficultyLevelName = key + '.difficultyLevel';
                counter++;
                return (
                    <div key={key} className='level field'>
                        <div className='level-left'>
                            <label>
                                {mapDifficultyLevelToLabel(level)}
                            </label>
                            <Field required name={placesCountName} type='number' min={0}/>
                            <Field name={difficultyLevelName} type='hidden'/>

                        </div>
                    </div>
                )
            })}
        </div>
    );
};

export default AwardedPlacesCountsFormPart;
