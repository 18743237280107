import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import ContestsPage from "./view/page/ContestsPage/ContestsPage";
import {connect} from "react-redux";
import ContestPage from "./view/page/ContestPage/ContestPage";
import AppState from "./redux/AppState";
import Loader, {DisplayMode} from "./view/common/component/Loader/Loader";
import {ROUTE} from "./routes";
import ContestNewPage from "./view/page/ContestPage/ContestNewPage";
import PupilCodesPage from "./view/page/PupilCodesPage/PupilCodesPage";
import ServerErrorInfo from "./view/common/component/ServerErrorInfo/ServerErrorInfo";
import {reduxDismissServerError} from "./redux/action/reduxHandleServerError";
import SignInPage from "./view/page/SignInPage/SignInPage";
import NavBar from "./view/common/component/NavBar/NavBar";
import PrivacyPolicyPage from "./view/page/PrivacyPolicyPage/PrivacyPolicyPage";

type Props = {
    hasCredentials: boolean,
    storageLoaded: boolean,
    signedOut: boolean,
    serverError: boolean
};

class Routing extends React.Component<Props> {
    render() {
        if (!this.props.storageLoaded) {
            return this.withLayout(
                <Loader displayMode={DisplayMode.fullscreen}/>
            );
        }

        if (this.props.signedOut) {
            return this.withLayout(
                <div>
                    <Route path='/' component={SignInPage}/>
                    <Route path='/**'>
                        <Redirect to={'/'}/>
                    </Route>
                </div>
            );
        }

        return this.withLayout(
            <div>
                {
                    !this.props.hasCredentials &&
                    <Route path='/' component={SignInPage}/>
                }

                <NavBar/>
                <Switch>
                    <Route exact path={ROUTE.PRIVACY_POLICY} component={PrivacyPolicyPage}/>
                    <Route exact path={ROUTE.CONTESTS} component={ContestsPage}/>
                    <Route exact path={ROUTE.CONTEST} component={ContestNewPage}/>
                    <Route exact path={ROUTE.CONTEST__ID()} render={(props) => {
                        return <ContestPage contestId={props.match.params.contestId}/>
                    }}/>
                    <Route exact path={ROUTE.CONTEST__ID__PUPIL_CODES()} render={(props) => {
                        return <PupilCodesPage contestId={props.match.params.contestId}/>
                    }}/>
                    <Route path='/'>
                        <Redirect to={ROUTE.CONTESTS}/>
                    </Route>
                </Switch>
            </div>
        );
    }

    withLayout = (content) => {
        return (
            <>
                {this.props.serverError &&
                    <Route path='/'>
                        <ServerErrorInfo onDismissClick={() => {
                            reduxDismissServerError()
                        }}/>
                    </Route>
                }
                {content}
            </>
        );
    }
}

const mapStateToProps = (state: AppState): Props => {
    return {
        hasCredentials: !!state.credentials?.accessToken,
        storageLoaded: !!state.storage?.loaded,
        signedOut: state.signedOut,
        serverError: state.serverError
    };
};
export default connect(mapStateToProps)(Routing);
