import {ACTION} from "../actionTypes";
import {store} from "../store";

const reduxSignOut = () => {
    store.dispatch({
        type: ACTION.SIGN_OUT
    })
};

export default reduxSignOut;

