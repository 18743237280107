import {AxiosResponse} from "axios";
import ApiResponseHeader from "./ApiResponseHeader";

enum ApiResponseStatus {
    OK = 'OK',
    SOME_ERROR = 'SOME_ERROR',
    CONNECTION_ERROR = 'CONNECTION_ERROR',
    NOT_FOUND = 'NOT_FOUND',
    BAD_PARAMS = 'BAD_PARAMS',
    UNAUTHORIZED = 'UNAUTHORIZED',
    INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
    SERVER_ERROR = 'SERVER_ERROR'
}

export default ApiResponseStatus;

export const extractApiStatus = (response?: AxiosResponse): ApiResponseStatus => {
    if (!response) {
        return ApiResponseStatus.CONNECTION_ERROR;
    }

    if (response.status < 400) {
        return ApiResponseStatus.OK;
    }

    const headers: object = response.headers;
    const header = headers ? headers[ApiResponseHeader.RESPONSE_STATUS] || null : null;
    if (!header) {
        return ApiResponseStatus.SOME_ERROR;
    }

    const matches = header.match(/\d\d\d\.\d\d/);

    if (!matches) {
        return ApiResponseStatus.SOME_ERROR;
    }

    const status: string = matches.pop() || '';

    return mapApiCode(status);
}

const mapApiCode = (code: string): ApiResponseStatus => {
    switch (code) {
        case '400.00':
        case '400.01':
            return ApiResponseStatus.BAD_PARAMS;
        case '401.00':
            return ApiResponseStatus.UNAUTHORIZED;
        case '401.01':
            return ApiResponseStatus.INVALID_CREDENTIALS;
        case '404.00':
            return ApiResponseStatus.NOT_FOUND;
        case '500.00':
        case '500.01':
            return ApiResponseStatus.SERVER_ERROR;
    }

    return ApiResponseStatus.SOME_ERROR;
}
