import React from "react";
import SchoolWithClasses from "../../../model/SchoolWithClasses";
import Class from "../../../model/Class";
import ClassInfo from "./ClassInfo";

type Props = {
    school: SchoolWithClasses
};

export default class SchoolArea extends React.Component<Props, any> {
    render() {
        return (
            <div className='school-area'>
                <h2 className='subtitle'>{this.props.school.shortName}</h2>
                <div>
                    {this.props.school.classes.map((clazz: Class) => {
                        return <ClassInfo key={clazz.id} class={clazz} />
                    })}
                </div>
            </div>
        );
    }
}
