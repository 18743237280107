import ProdContainer from "./ProdContainer";

class AppContainer {
    private static container: ProdContainer;
    
    public static setContainer = (container: ProdContainer) => {
        AppContainer.container = container;
    };

    public static getContainer = () => {
        return AppContainer.container;
    }
}

export default AppContainer;
