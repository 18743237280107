import SchoolWithClasses from "./SchoolWithClasses";
import Contest from "./Contest";
import moment from "moment-timezone";
import ListContest from "./ListContest";
import "moment/locale/pl";

moment.tz.setDefault("Europe/Warsaw");

interface ContestWithSchoolsAndClasses extends Contest {
    schools: Array<SchoolWithClasses>,
    pupilCodesFileUrl: string | null
}

export const normalizeContestForReading = (contest: | ContestWithSchoolsAndClasses | ListContest) => {
    if (contest.contestModeEnd) {
        contest.contestModeEnd = moment(contest.contestModeEnd).format('YYYY-MM-DD HH:mm');
    }
    if (contest.contestModeStart) {
        contest.contestModeStart = moment(contest.contestModeStart).format('YYYY-MM-DD HH:mm');
    }

    return contest;
};

export const normalizeContestForWriting = (contest: Contest | ContestWithSchoolsAndClasses) => {
    let startDate = contest.contestModeStart && contest.contestModeStart.trim();
    let endDate = contest.contestModeEnd && contest.contestModeEnd.trim();

    contest.contestModeStart = startDate ? moment(startDate).format() : null;
    contest.contestModeEnd = endDate ? moment(endDate).format() : null;
    contest.sponsorLogoFileId = contest.sponsorLogoFileId || null;

    return contest;
};


export default ContestWithSchoolsAndClasses;
