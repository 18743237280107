import React from "react";
import './style.scss';
import Icon from "../Icon/Icon";

class SuccessSign extends React.Component<any, any> {

    render() {
        return (
            <Icon icon='fa-check' className='success-sign' />
        );
    }
}

export default SuccessSign;
