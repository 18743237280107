import {ACTION} from "../actionTypes";
import signIn from "../../action/signIn";

const reduxSignIn = (username: string, password: string) => (dispatch: (o: object) => any) => {
    return signIn(username, password)
        .onSuccess((credentials) => {
            dispatch({
                type: ACTION.CREDENTIALS.SAVE,
                credentials
            })
        });
};

export default reduxSignIn;

