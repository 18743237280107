import React from "react";
import FormState from "../state/FormState";
import ApiPromise from "../../../infrastructure/apiClient/ApiPromise";

const asyncFormSubmitted = (component: React.Component<any, {formState: FormState}>, action: () => ApiPromise<any>) => {
    component.setState({
        formState: component.state.formState.submitted()
    });

    return action()
        .onSuccess(v => {
            component.setState({
                formState: component.state.formState.finishedWithSuccess()
            });
            return v;
        })
        .onClientError(() => {
            component.setState({
                formState: component.state.formState.finishedWithError()
            });
        })
        .onServerError(() => {
            component.setState({
                formState: component.state.formState.finishedWithError()
            });
        })
        .catch(e => {
            component.setState({
                formState: component.state.formState.finishedWithError()
            });
            return Promise.reject(e);
        })
};

export default asyncFormSubmitted;
