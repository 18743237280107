import ApiConnection from "../ApiConnection";
import Contest from "../../../model/Contest";

export const get = (): Promise<any> => {
    return ApiConnection.get('/contests');
};

type ContestBody = Contest & {
    id?: any
};

export const post = (body: ContestBody): Promise<any> => {
    body['contestModeStart'] = body['contestModeStart'] || null;
    body['contestModeEnd'] = body['contestModeEnd'] || null;
    body['sponsorLogoFileId'] = body['sponsorLogoFileId'] || null;
    return ApiConnection.post('/contests', body);
};
