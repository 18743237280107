import { AxiosError, AxiosResponse } from "axios";
import ApiResponseStatus, { extractApiStatus } from "./ApiResponseStatus";

enum Status {
    SUCCESS = 'SUCCESS',
    CLIENT_ERROR = 'CLIENT_ERROR',
    SERVER_ERROR = 'SERVER_ERROR',
}

type Payload<TPass> = {
    pass: TPass,
    generalStatus: Status,
    responseStatus: ApiResponseStatus
};

type Defaults = {
    onSuccess?: (response: AxiosResponse, status: ApiResponseStatus) => AxiosResponse,
    onClientError?: (response: AxiosResponse, status: ApiResponseStatus) => AxiosResponse,
    onServerError?: (response: AxiosResponse, status: ApiResponseStatus) => AxiosResponse
};

export default class ApiPromise<TResponse, TException = AxiosError> {
    private promise: Promise<Payload<TResponse>>;
    private static defaults?: Defaults;

    public static fromAxiosPromise = (promise: Promise<AxiosResponse>): ApiPromise<AxiosResponse, AxiosError> => {
        const apiResponse = new ApiPromise<AxiosResponse, AxiosError>(
            promise
            .then((response) => {
                return {
                    pass: response,
                    generalStatus: Status.SUCCESS,
                    responseStatus: extractApiStatus(response)
                } as Payload<AxiosResponse>;
            }).catch((error: AxiosError) => {
                if (!error.response) {
                    throw error;
                }

                if (error.response.status >= 400 && error.response.status < 500) {
                    return {
                        pass: error.response,
                        generalStatus: Status.CLIENT_ERROR,
                        responseStatus: extractApiStatus(error.response)
                    } as Payload<AxiosResponse>;
                }

                return {
                    pass: error.response,
                    generalStatus: Status.SERVER_ERROR,
                    responseStatus: extractApiStatus(error.response)
                } as Payload<AxiosResponse>;
            }) as Promise<Payload<AxiosResponse>>
        );

        if (ApiPromise.defaults?.onSuccess) {
            apiResponse.onSuccess(ApiPromise.defaults.onSuccess);
        }

        if (ApiPromise.defaults?.onClientError) {
            apiResponse.onClientError(ApiPromise.defaults.onClientError);
        }

        if (ApiPromise.defaults?.onServerError) {
            apiResponse.onServerError(ApiPromise.defaults.onServerError);
        }

        return apiResponse;
    }

    private constructor(promise: Promise<Payload<TResponse>>) {
        this.promise = promise;
    }

    public static setDefaults = (defaults: Defaults) => {
        ApiPromise.defaults = defaults;
    };

    public onSuccess = (callback: (response: TResponse, status: ApiResponseStatus) => any): ApiPromise<any, any> => {
        return this.onStatus(callback, Status.SUCCESS);
    };

    public onClientError = (callback: (response: TResponse, status: ApiResponseStatus) => any): ApiPromise<any, any> => {
        return this.onStatus(callback, Status.CLIENT_ERROR);
    };

    public onServerError = (callback: (response: TResponse, status: ApiResponseStatus) => any): ApiPromise<any, any> => {
        return this.onStatus(callback, Status.SERVER_ERROR);
    };

    public then = (callback: (response: TResponse, status: ApiResponseStatus) => any): ApiPromise<any, any> => {
        return this.onStatus(callback);
    };


    private onStatus = (callback: (response: TResponse, status: ApiResponseStatus) => any, generalStatus?: Status): ApiPromise<any, any> => {
        this.promise = this.promise.then((response: Payload<TResponse>) => {

            if (generalStatus && response.generalStatus !== generalStatus) {
                return response;
            }

            return {
                pass: callback(response.pass, response.responseStatus),
                generalStatus: response.generalStatus,
                responseStatus: response.responseStatus
            };
        });
        return new ApiPromise<any, any>(this.promise);
    }

    public catch = (onerror?: (error: TException) => any): ApiPromise<any> => {
        return new ApiPromise<any>(this.promise.catch(onerror ? onerror : () => {}));
    };

    public finally = (onfinally: () => any): ApiPromise<any> => {
        return new ApiPromise<any>(this.promise.finally(onfinally));
    };

    public getPromise = () => {
        return this.promise
            .then((payload?: Payload<any>) => {
                return payload?.pass;
            });
    }
}
