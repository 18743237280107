import * as apiClient from "../infrastructure/apiClient/apiClient";
import uuid from "../model/uuid";
import ApiPromise from "../infrastructure/apiClient/ApiPromise";

type UploadSponsorLogoFileResponse = {
    fileId: uuid,
    logoFileUrl: string
};

const uploadSponsorLogoFile = (data): ApiPromise<UploadSponsorLogoFileResponse> => {
    return ApiPromise.fromAxiosPromise(apiClient.sponsorLogoFiles.post(data))
        .onSuccess(response => response.data);
};

export default uploadSponsorLogoFile;
