type EnvVars = {
    REACT_APP__API_HOST?: string
}

type AppConfig = {
    apiHost: string
}

const envVars: EnvVars = process.env as EnvVars;

const defaultConfig: AppConfig = {
    apiHost: 'https://psm1.test.sisms.pl'
};

const appConfig: AppConfig = {
    apiHost: envVars.REACT_APP__API_HOST || defaultConfig.apiHost
};

export default appConfig;

