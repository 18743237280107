import {ACTION} from "../actionTypes";
import AppContainer from "../../AppContainer";

export const reduxHandleServerError = () => {
    AppContainer.getContainer().getReduxStore().dispatch({
        type: ACTION.SERVER_ERROR.SHOW
    });

    setTimeout(() => {
        reduxDismissServerError();
    }, 10000);
};

export const reduxDismissServerError = () => {
    AppContainer.getContainer().getReduxStore().dispatch({
        type: ACTION.SERVER_ERROR.DISMISS
    });
};
