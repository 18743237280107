import React from "react";
import {Field, Form, Formik} from "formik";
import PrivacyPolicy from "../../../model/PrivacyPolicy";
import asyncFormSubmitted from "../../common/form/asyncFormSubmitted";
import FormState from "../../common/state/FormState";
import FormikOnRender from "../../common/form/FormikOnRender";
import savePrivacyPolicy from "../../../action/savePrivacyPolicy";
import getPrivacyPolicy from "../../../action/getPrivacyPolicy";
import isLoadingState from "../../common/state/isLoadingState";
import Loader, {DisplayMode} from "../../common/component/Loader/Loader";

type State = isLoadingState & {
    formState: FormState;
    privacyPolicy: PrivacyPolicy
};

class PrivacyPolicyPage extends React.Component<any, State> {
    constructor(props) {
        super(props);
        this.state = {
            formState: new FormState(),
            isLoading: true,
            privacyPolicy: {content: ''}
        }
    }

    componentDidMount() {
        getPrivacyPolicy()
            .onSuccess((policy: PrivacyPolicy) => {
                console.log(policy)
                this.setState({privacyPolicy: policy})
            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            });
    }

    render() {
        return (
            <div id="privacy-policy-page" className='page'>
                {
                    this.state.isLoading &&
                    <Loader displayMode={DisplayMode.fillParent}/>
                }

                <div className='column is-half'>
                    <Formik onSubmit={this.onSubmit} initialValues={this.state.privacyPolicy} enableReinitialize={true}>
                        {(form: FormikOnRender) => (
                            <Form onChange={this.onChange}>
                                <Field name='content' as='textarea' className='field'/>
                                <button disabled={!(this.state.formState.canBeSubmitted() && form.dirty)}
                                        type='submit'
                                        className='is-primary is-pulled-right'>
                                    Zapisz
                                </button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        );
    };

    private onSubmit = (values: PrivacyPolicy) => {
        asyncFormSubmitted(this, () => {
            return savePrivacyPolicy(values)
        })
    }

    private onChange = () => {
        this.setState({
            formState: this.state.formState.changed()
        })
    }
}

export default PrivacyPolicyPage;
