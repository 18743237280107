import {ACTION} from "../actionTypes";

const signedOutReducer = (currentState = {}, action: { type: string }) => {
    switch (action.type) {
        case ACTION.SIGN_OUT:
            return true;
        case ACTION.CREDENTIALS.SAVE:
            return false;
    }

    return currentState;
};

export default signedOutReducer;
