import React from "react";
import {Field, Form, Formik, FormikHelpers} from "formik";
import mapDifficultyLevelToLabel from "../mapDifficultyLevelToLabel";
import Class from "../../../../model/Class";
import FormState from "../../../common/state/FormState";
import FormikOnRender from "../../../common/form/FormikOnRender";
import uuid from "../../../../model/uuid";
import Loader, {DisplayMode} from "../../../common/component/Loader/Loader";
import DifficultyLevel from "../../../../model/DifficultyLevel";
import asyncFormSubmitted from "../../../common/form/asyncFormSubmitted";
import submitClass from "../../../../action/submitClass";
import deleteClass from "../../../../action/deleteClass";
import ConfirmationDialog from "../../../common/component/ConfirmationDialog/ConfirmationDialog";
import School from "../../../../model/School";
import FailSign from "../../../common/component/FailSign/FailSign";
import SuccessSign from "../../../common/component/SuccessSign/SuccessSign";
import * as Yup from "yup";
import FieldError from "../../../common/component/FieldError/FieldError";


type Props = {
    schoolId: string,
    school?: School,
    class?: Class | undefined,
    onClassCreated: (clazz: Class) => void,
    onClassDeleted: (classId: uuid) => void
};

type State = {
    formState: FormState,
    deletionHasToBeConfirmed?: boolean
};

const validationSchema = Yup.object().shape({
    name:  Yup
        .string()
        .trim()
        .min(1, 'Min. 1 znak')
        .max(10, 'Max. 10 znaków'),
    difficultyLevel: Yup
        .string()

});

class ClassForm extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            formState: new FormState()
        };
    }

    render() {
        const initialFormValues: any = {
            schoolId: this.props.schoolId,
            name: '',
            difficultyLevel: ''
        };

        return (
            <>
                {this.state.deletionHasToBeConfirmed &&
                <ConfirmationDialog
                    title='Usunąć wybraną klasę?'
                    message={this.classDeletionMessage()}
                    onOkClick={this.classDeletionConfirmed}
                    onCancelClick={this.classDeletionCanceled}
                />
                }
                <Formik validationSchema={validationSchema}
                        initialValues={this.props.class || initialFormValues}
                        onSubmit={this.onSubmit}>
                    {(form: FormikOnRender) => (
                        <Form className='class-form level' onChange={this.onChange}>

                            <Field type='hidden' name='schoolId'/>
                            <div className='level-left  loadable'>

                                {this.state.formState.isLoading &&
                                <Loader displayMode={DisplayMode.fillParent}/>
                                }

                                <div>
                                    <div>
                                        <Field name='name' placeholder='Nazwa' required/>
                                    </div>
                                    <FieldError name="name"/>
                                </div>
                                <div className=''>
                                    <div className='select'>
                                        <Field className='select'
                                               required
                                               name='difficultyLevel'
                                               as='select'>
                                            <option disabled value=''>Poziom</option>
                                            {Object.values(DifficultyLevel).map((difficultyLevel) => {
                                                return (
                                                    <option
                                                        key={difficultyLevel}
                                                        value={difficultyLevel}>
                                                        {mapDifficultyLevelToLabel(difficultyLevel)}
                                                    </option>
                                                );
                                            })}
                                        </Field>
                                    </div>

                                </div>
                                <div className='class-form--controls'>

                                    <div className='is-pulled-left'>
                                        {
                                            this.state.formState.hasFailed() &&
                                            <FailSign />
                                        }
                                        {
                                            this.state.formState.hasSucceeded() &&
                                            this.props.class &&
                                            <SuccessSign />
                                        }
                                        <button disabled={!(this.state.formState.canBeSubmitted() && form.dirty)}
                                                className=' is-primary'>
                                            {this.props.class
                                                ? 'Zapisz'
                                                : 'Dodaj klasę'
                                            }
                                        </button>
                                        {
                                            this.props.class &&
                                            <button type='button' className=''
                                                    onClick={() => this.onDeleteClicked()}>
                                                Usuń
                                            </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </>
        );
    }

    onSubmit = (values: Class, helpers: FormikHelpers<any>) => {
        asyncFormSubmitted(this, () => {
            return submitClass(values)
                .onSuccess((classId) => {
                    if (classId) {
                        helpers.resetForm();

                        const createdClass: Class = {
                            ...values,
                            id: classId
                        };

                        this.props.onClassCreated(
                            createdClass
                        );
                    }
                })
        });

    };

    private classDeletionMessage = (): string => {
        return 'Czy na pewno usunąć klasę "'
            + this.props.class?.name +
            '" ze szkoły "'
            + this.props.school?.shortName + '"?'
    };

    private onDeleteClicked = () => {
        this.setState({
            deletionHasToBeConfirmed: true
        })
    };

    private classDeletionConfirmed = () => {
        this.setState({
            deletionHasToBeConfirmed: false
        });
        asyncFormSubmitted(this, () => {
            return deleteClass(this.props.class?.id as uuid)
                .onSuccess(() => {
                    this.props.onClassDeleted(this.props.class?.id as uuid);
                })
        });
    };

    private classDeletionCanceled = () => {
        this.setState({
            deletionHasToBeConfirmed: false
        })
    };

    private onChange = () => {
        this.setState({
            formState: this.state.formState.changed()
        })
    }
}

export default ClassForm;
