import React from "react";
import {Redirect} from "react-router-dom";
import ContestForm from "./form/ContestForm";
import './style.scss'
import IsLoadingState from "../../common/state/isLoadingState";
import Loader, {DisplayMode} from "../../common/component/Loader/Loader";
import getContest from "../../../action/getContest";
import uuid from "../../../model/uuid";
import Contest from "../../../model/Contest";
import submitContest from "../../../action/submitContest";
import {ROUTE} from "../../../routes";
import SchoolsSection from "./SchoolsSection";
import ContestWithSchoolsAndClasses from "../../../model/ContestWithSchoolsAndClasses";
import SchoolWithClasses from "../../../model/SchoolWithClasses";

type State = IsLoadingState & {
    contest?: ContestWithSchoolsAndClasses | null,
    isNew: boolean,
    createdContestId: string | null
};
type Props = {
    contestId?: uuid;
};

class ContestPage extends React.Component<Props, State> {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            isNew: false,
            createdContestId: null
        };
    }

    componentDidMount(): void {
        if (!this.props.contestId) {
            this.setState({isLoading: false, isNew: true});
            return;
        }

        getContest(this.props.contestId)
            .onSuccess((contest: ContestWithSchoolsAndClasses) => {
                this.setState({
                    contest: contest
                })
            })
            .catch(() => {

            })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    render() {
        if (this.state.isLoading) {
            return this.withLayout(<Loader displayMode={DisplayMode.fillParent}/>);
        }

        if (this.state.createdContestId) {
            return <Redirect to={ROUTE.CONTEST__ID({contestId: this.state.createdContestId})}/>
        }

        const schools: Array<SchoolWithClasses> = (this.state.contest && this.state.contest.schools) || [];

        return this.withLayout(
            <div className='columns'>
                <div className='column is-7'>
                    <section className='section is-paddingless'>
                        <h1 className='title'>DANE KONKURSU</h1>
                        <div className='tile is-parent is-vertical is-paddingless'>
                            <ContestForm contest={this.state.contest} submitContest={this.onContestSubmit}/>
                        </div>
                    </section>

                </div>

                <div className={'column is-5 ' + (this.state.isNew ? 'is-hidden' : '')}>
                    <section className='section is-paddingless'>
                        <h1 className='title'>SZKOŁY I KLASY</h1>
                        {
                            this.props.contestId &&
                            <SchoolsSection contestId={this.props.contestId} schools={schools}/>
                        }

                    </section>
                </div>
            </div>
        );
    }

    private withLayout = (content) => {
        return (
            <div id='contest-page' className='page'>
                {content}
            </div>
        );
    };

    onContestSubmit = (contest: Contest) => {
        if (this.state.isNew) {
            this.setState({isLoading: true});
        }
        return submitContest(contest)
            .onSuccess((contestId: uuid | void) => {
                if (contestId) {
                    this.setState({createdContestId: contestId})
                }
                return contestId;
            })
            .finally(() => {
                this.setState({isLoading: false})
            })
    };


}

export default ContestPage;
