import {ACTION} from "../actionTypes";

const credentialsReducer = (currentState = {}, action: { type: string, credentials: object }) => {
    switch (action.type) {
        case  ACTION.CREDENTIALS.SAVE:
            return action.credentials;
        case ACTION.CREDENTIALS.DELETE:
        case ACTION.SIGN_OUT:
            return {};
    }

    return currentState;
};
export default credentialsReducer;
