import uuid from "../../../model/uuid";
import ApiConnection from "../ApiConnection";
import DifficultyLevel from "../../../model/DifficultyLevel";

type PutBody = {
    name: string,
    difficultyLevel: DifficultyLevel
};

export const put = (classId: uuid, body: PutBody) => {
    return ApiConnection.put('/classes/' + classId, body);
};

export const Delete = (classId: uuid) => {
    return ApiConnection.delete('/classes/' + classId);
};
