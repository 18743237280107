import React from "react";
import {Field, Form, Formik} from 'formik';
import Contest from "../../../../model/Contest";
import DifficultyLevel from "../../../../model/DifficultyLevel";
import AwardedPlacesCountsFormPart from "./AwardedPlacesCountsFormPart";
import LabeledField from "../../../common/form/LabeledField";
import ScoreThresholdsFormPart from "./ScoreThresholdsFormPart";
import uuid from "../../../../model/uuid";
import Loader, {DisplayMode} from "../../../common/component/Loader/Loader";
import SponsorLogoFileUploader from "./SponsorLogoFileUploader";
import SuccessSign from "../../../common/component/SuccessSign/SuccessSign";
import FormState from "../../../common/state/FormState";
import asyncFormSubmitted from "../../../common/form/asyncFormSubmitted";
import * as Yup from 'yup';
import FormikOnRender from "../../../common/form/FormikOnRender";
import FailSign from "../../../common/component/FailSign/FailSign";
import ApiPromise from "../../../../infrastructure/apiClient/ApiPromise";
import FieldError from "../../../common/component/FieldError/FieldError";

const initialFormValues: Contest = {
    id: '',
    tenantName: '',
    treesInfo: '',
    terms: '',
    prizesInfo: '',
    sponsorLogoFileId: '',
    sponsorLogoFileUrl: '',
    consent: '',
    awardedPlacesCounts: Object.values(DifficultyLevel).map((level) => {
        return {
            placesCount: 0,
            difficultyLevel: level
        }
    }),
    scoreThresholds: Object.values(DifficultyLevel).map((level) => {
        return {
            scoreInPercent: 0,
            difficultyLevel: level
        }
    }),
    classRankingEnabled: false,
    contestModeStart: '',
    contestModeEnd: ''
};

type Props = {
    contest?: Contest | null;
    submitContest: (values: Contest) => ApiPromise<uuid | void>;
};

type State = {
    contest: Contest;
    formState: FormState
};

const validationSchema = Yup.object().shape({
    tenantName: Yup
        .string()
        .trim()
        .min(1, 'Min. 1 znak')
        .required('Wymagane'),
    contestModeStart: Yup
        .string()
        .nullable()
        .trim()
        .test('', 'Zły Format (YYYY-MM-DD hh:mm)', (value) => {
            return (
                !value
                || Yup.date().isValidSync(value)
                || new RegExp('^\\d{4}-\\d\\d-\\d\\d \\d\\d:\\d\\d$').test(value)
            )
        }),
    contestModeEnd: Yup
        .string()
        .nullable()
        .trim()
        .test('', 'Zły Format (YYYY-MM-DD hh:mm)', (value) => {
            return (
                !value
                || Yup.date().isValidSync(value)
                || new RegExp('^\\d{4}-\\d\\d-\\d\\d \\d\\d:\\d\\d$').test(value)
            )
        })

});


class ContestForm extends React.Component<Props, State> {
    public constructor(props) {
        super(props);
        this.state = {
            contest: props.contest || initialFormValues,
            formState: new FormState()
        };
    }

    render() {
        return (
            <Formik
                initialValues={this.state.contest}
                validationSchema={validationSchema}
                enableReinitialize={true}
                validateOnChange={false}
                onSubmit={(values) => {
                    this.onSubmit(values)
                }}>
                {(form: FormikOnRender) => (
                    <Form
                        id='contest-form'
                        className='loadable'
                        onChange={() => {
                            this.setState({formState: this.state.formState.changed()})
                        }}>

                        {this.state.formState.isLoading && <Loader displayMode={DisplayMode.fillParent}/>}

                        <LabeledField className='field' name='tenantName' label='Klient' required>
                            <FieldError name="tenantName"/>
                        </LabeledField>
                        <div className={!this.state.contest.id ? 'is-hidden' : ''}>
                            <Field name='id' type='hidden'/>
                            <div className='field'>
                                <SponsorLogoFileUploader
                                    onLogoFileUploaded={this.onLogoFileUploaded}
                                    onLogoFileRemoved={this.onLogoFileRemoved}
                                    logoFileUrl={this.state.contest && this.state.contest.sponsorLogoFileUrl}/>
                                <Field type='hidden'
                                       name='sponsorLogoFileId'/>
                            </div>
                            <LabeledField className='field' name='treesInfo' as='textarea' label='Info o drzewach'/>
                            <LabeledField className='field' name='prizesInfo' as='textarea' label='Info o nagrodach'/>
                            <label className='checkbox field'>
                                <Field name='classRankingEnabled' type="checkbox"/>
                                Ranking klasowy dostępny?
                            </label>
                            <div className='columns field'>
                                <div className='column is-half'>
                                    <LabeledField
                                        name='contestModeStart'
                                        placeholder="YYYY-MM-DD hh:mm"
                                        // pattern='\d{4}-(0\d|1[0-2])-([0-2]\d|3[01]) ([01]\d|2[0-4]):[0-5]\d'
                                        label='Data rozpoczęcia trybu konkursowego'/>
                                    <FieldError name={'contestModeStart'}/>
                                </div>
                                <div className='column is-half'>
                                    <LabeledField
                                        name='contestModeEnd'
                                        placeholder="YYYY-MM-DD hh:mm"
                                        // pattern='\d{4}-(0\d|1[0-2])-([0-2]\d|3[01]) ([01]\d|2[0-4]):[0-5]\d'
                                        label='Data zakończenia trybu konkursowego'/>
                                    <FieldError name={'contestModeEnd'}/>
                                </div>
                            </div>
                            <div className='columns field'>
                                <fieldset className='column is-half'>
                                    <legend className='subtitle'>Liczba nagradzanych miejsc</legend>
                                    <AwardedPlacesCountsFormPart/>
                                </fieldset>
                                <fieldset className='column is-half'>
                                    <legend className='subtitle'>Min. % pkt. do TK</legend>
                                    <ScoreThresholdsFormPart/>
                                </fieldset>
                            </div>

                            <LabeledField name='terms' label='Regulamin' as='textarea'/>
                            <LabeledField name='consent' label='Zgoda' as='textarea'/>
                        </div>
                        <div id='contest-form--controls' className='is-pulled-right'>
                            {
                                this.state.formState.hasSucceeded() &&
                                <SuccessSign/>
                            }
                            {
                                this.state.formState.hasFailed() &&
                                <FailSign/>
                            }
                            {/*@todo check why form.dirty doesn't work when file uploaded/removed*/}
                            <button className='is-primary'
                                    type='submit'
                                    // disabled={!(this.state.formState.canBeSubmitted() && form.dirty)}>
                                    disabled={!(this.state.formState.canBeSubmitted())}>
                                Zapisz
                            </button>
                        </div>

                    </Form>
                )}
            </Formik>

        );
    }

    private onSubmit = (values: Contest) => {
        asyncFormSubmitted(this, () => {
            return this.props.submitContest(values);
        }).catch(() => {

        });
    };

    private onLogoFileUploaded = (fileId: uuid) => {
        this.setState({
            contest: {
                ...this.state.contest as Contest,
                sponsorLogoFileId: fileId,
            },
            formState: this.state.formState.changed()
        });
    };

    private onLogoFileRemoved = () => {
        this.setState({
            contest: {
                ...this.state.contest as Contest,
                sponsorLogoFileId: ''
            },
            formState: this.state.formState.changed()
        });
    }
}

export default ContestForm;
