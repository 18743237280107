import React from "react";
import './style.scss';

type Props = {
    title?: string,
    message: string,
    onOkClick: () => any,
    onCancelClick?: () => any
}
;

export default class ConfirmationDialog extends React.Component<Props, any> {
    render() {
        return (
            <div className='confirmation-dialog'>
                <div className='confirmation-dialog--title'>
                    {this.props.title}
                </div>
                <div className='confirmation-dialog--message'>
                    {this.props.message}
                </div>
                <div className='confirmation-dialog--buttons'>
                    <button onClick={this.props.onOkClick} className='confirmation-dialog--ok-btn'>OK</button>
                    <button onClick={this.props.onCancelClick} className='confirmation-dialog--cancel-btn'>Anuluj</button>
                </div>
            </div>
        );
    };
}
