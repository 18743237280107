import ApiConnection from "../ApiConnection";
import PrivacyPolicy from "../../../model/PrivacyPolicy";

export const get = () => {
    return ApiConnection.get('/privacy-policy');
};

export const put = (body: PrivacyPolicy) => {
    return ApiConnection.put('/privacy-policy', body);
};
