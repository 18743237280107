export default class FormState {
    public isLoading: boolean = false;
    private success: boolean | null = null;
    public hasChanges: boolean = false;

    public reset = (): FormState => {
        this.isLoading = false;
        this.success = null;
        this.hasChanges = false;
        return this;
    };

    public submitted = (): FormState => {
        this.success = null;
        this.isLoading = true;
        return this;
    };

    public finishedWithSuccess = (): FormState => {
        this.reset();
        this.success = true;
        return this;
    };

    public finishedWithError = (): FormState => {
        this.isLoading = false;
        this.success = false;
        return this;
    };

    public changed = (): FormState => {
        this.hasChanges = true;
        this.success = null;
        return this;
    };

    public canBeSubmitted = (): boolean => {
        return !this.isLoading && this.hasChanges;
    };

    public hasFailed = () => {
        return this.success === false;
    };

    public hasSucceeded = () => {
        return this.success === true;
    };
}
