import React from "react";
import ClassForm from "./form/ClassForm";
import SchoolWithClasses from "../../../model/SchoolWithClasses";
import Class, { createClassesFromText } from "../../../model/Class";
import uuid from "../../../model/uuid";
import { Field, Form, Formik } from "formik";
import SchoolForm from "./form/SchoolForm";
import submitClass from "../../../action/submitClass";

type State = {
    collapsed: boolean,
    school?: SchoolWithClasses,
    isNew: boolean,
    classes: Array<Class>
};

type Props = {
    school?: SchoolWithClasses,
    contestId: uuid,
    onSchoolCreated?: (school: SchoolWithClasses) => void
};

class SchoolSubSection extends React.Component<Props, State> {
    public constructor(props) {
        super(props);
        this.state = {
            collapsed: !!this.props.school?.id || false,
            school: this.props.school,
            isNew: !this.props.school?.id,
            classes: this.props.school?.classes || []
        };

    }

    render() {

        if (this.state.collapsed) {
            return this.withLayout(
                <div className='school-sub-section--header tile level'
                     onClick={this.onCollapsedClick}>
                    <h2 className='level-left'>{this.state.school?.shortName}</h2>
                    <span className="icon level-right">
                          <i className="fas fa-chevron-down"></i>
                        </span>
                </div>
            );
        }

        if (!this.state.school?.id) {
            return this.withLayoutAndForm('');
        }

        return this.withLayoutAndForm(
            <div className='school-sub-section--classes'>
                <h2 className='subtitle'>Klasy</h2>
                <div>
                    <ClassForm
                        key={0}
                        schoolId={this.props.school?.id as uuid}
                        onClassCreated={this.onClassCreated}
                        onClassDeleted={(classId: uuid) => {}}/>
                    {
                        this.state.classes
                            .sort((clazz1, clazz2) => clazz1.name > clazz2.name ? 1 : -1)
                            .map((clazz, no) => {
                            return <ClassForm schoolId={this.props.school?.id || ''}
                                              school={this.props.school}
                                              key={clazz.id}
                                              class={clazz}
                                              onClassCreated={this.onClassCreated}
                                              onClassDeleted={() => this.onClassDeleted(clazz.id as uuid)}
                            />
                        })
                    }
                </div>

                <div className='school-sub-section--classes--bulk-add'>
                    <h3 className='subtitle'>Dodawanie wielu klas</h3>
                    <Formik initialValues={{rawClassesText: ''}}
                            onSubmit={this.onBulkClassesAddFormSubmit}>
                        {() => (
                            <Form>
                                <div>
                                    <Field as='textarea'  name='rawClassesText' placeholder='Wklej listę klas oddzielone nową linią albo przecinkiem lub średnikiem'/>

                                    {/*<textarea name='rawClassesText' placeholder='Wklej listę klas oddzielone nową linią albo przecinkiem lub średnikiem' />*/}
                                </div>
                                <button type='submit' className='is-primary'>Dodaj</button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        );

    }

    private withLayoutAndForm = (content) => {
        return this.withLayout(
            <div>
                {
                    !this.state.isNew &&
                    <div className='school-sub-section--header tile level' onClick={this.onExpandedClick}>
                        <h2 className='level-left'>&nbsp;</h2>
                        <span className="icon level-right">
                          <i className="fas fa-chevron-up"></i>
                        </span>
                    </div>
                }

                <div className='tile is-child'>
                    <SchoolForm contestId={this.props.contestId} school={this.props.school} onSchoolCreated={this.props.onSchoolCreated}/>
                    <div className='is-clearfix'/>
                </div>
                <div className='tile is-child'>
                    {content}
                </div>

            </div>
        );
    };

    private withLayout = (content) => {
        let classes = 'school-sub-section tile is-parent is-vertical';
        if (this.state.collapsed) {
            classes += ' -is-collapsed';
        }
        return (
            <div className={classes}>
                {content}
            </div>
        );
    };


    onCollapsedClick = (e) => {
        this.setState({
            collapsed: false
        });
    };

    onExpandedClick = (e) => {
        this.setState({
            collapsed: true
        });
    };

    onClassCreated = (clazz: Class) => {
        this.setState({
            classes: [
                clazz,
                ...this.state.classes
            ]
        })
    };

    onClassDeleted = (classId: uuid) => {
        const classes = this.state.classes.filter((clazz: Class) => clazz.id !== classId);
        this.setState({
            classes
        })
    }

    onBulkClassesAddFormSubmit = (values: {rawClassesText: string}) => {
        const classes = createClassesFromText(values.rawClassesText, this.props.school?.id as uuid);

        classes.forEach(clazz => submitClass(clazz)
            .onSuccess((classId) => {
                if (classId) {
                    const createdClass: Class = {
                        ...clazz,
                        id: classId
                    };

                    this.onClassCreated(
                        createdClass
                    );
                }
            }))
    }
}

export default SchoolSubSection;
