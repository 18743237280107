import DifficultyLevel from "../../../model/DifficultyLevel";

const mapDifficultyLevelToLabel = (level: DifficultyLevel): string => {
    switch (level) {
        case DifficultyLevel.PRIMARY_1_2:
            return 'Klasy 1-2';
        case DifficultyLevel.PRIMARY_3_5:
            return 'Klasy 3-5';
        case DifficultyLevel.PRIMARY_6_8:
            return 'Klasy 6-8';
    }

    return '';
};


export default mapDifficultyLevelToLabel;
