import uuid from "../../../model/uuid";
import ApiConnection from "../ApiConnection";
import DifficultyLevel from "../../../model/DifficultyLevel";

type PostBody = {
    name: string,
    difficultyLevel: DifficultyLevel
};

export const post = (schoolId: uuid, body: PostBody) => {
    return ApiConnection.post('/schools/' + schoolId + '/classes', body);
};
