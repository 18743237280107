import uuid from "../../../model/uuid";
import ApiConnection from "../ApiConnection";

export const get = (schoolId: uuid) => {
    return ApiConnection.get('/schools/' + schoolId);
};

type PutBody = {
    name: string,
    shortName: string,
    address: string
};

export const put = (schoolId: uuid, body: PutBody) => {
    return ApiConnection.put('/schools/' + schoolId, body);
};
