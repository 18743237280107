import {ACTION} from "../actionTypes";

const serverErrorReducer = (state = {}, action: {type: string}) => {
    switch (action.type) {
        case ACTION.SERVER_ERROR.SHOW:
            return true;
        case ACTION.SERVER_ERROR.DISMISS:
            return false;
    }

    return state;
};

export default serverErrorReducer;
