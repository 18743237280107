import React from "react";
import './style.scss';

type Props = {
    icon: string,
    className?: string,
    title?: string,
    onClick?: () => void
};

class Icon extends React.Component<Props, any> {
    render() {
        const iClasses = 'fas ' + this.props.icon;

        let className = 'icon an-icon ' + this.props.className ;

        return (
            <span title={this.props.title} className={className} onClick={this.props.onClick}>
                <i className={iClasses}></i>
            </span>
        );
    }
}

export default Icon;
