import * as apiClient from "../infrastructure/apiClient/apiClient";
import reduxSignOut from "../redux/action/reduxSignOut";
import ApiPromise from "../infrastructure/apiClient/ApiPromise";

const signOut = (): ApiPromise<void> => {
    return ApiPromise.fromAxiosPromise(apiClient.meSessions.Delete())
        .onSuccess(() => {
            reduxSignOut();
        });
};

export default signOut;
