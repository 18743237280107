import uuid from "../model/uuid";
import * as apiClient from "../infrastructure/apiClient/apiClient";
import ApiPromise from "../infrastructure/apiClient/ApiPromise";

const deleteClass = (classId: uuid): ApiPromise<void> => {
    return ApiPromise.fromAxiosPromise(apiClient.classesId.Delete(classId))
        .onSuccess(() => {
            return
        });
};

export default deleteClass;
