import uuid from "../../../model/uuid";
import ApiConnection from "../ApiConnection";
import Contest from "../../../model/Contest";

export const get = (contestId: uuid): Promise<any> => {
    return ApiConnection.get('/contests/' + contestId);
};

type PutBody = Contest;

export const put = (contestId: uuid, body: PutBody): Promise<any> => {
    return ApiConnection.put('/contests/' + contestId, body);
};

type PatchBody = {
    generatePupilCodes?: {
        count: number
    },
    generatePupilCodesDocument?: {},
    generateRankingsDocument?: {},
    generateStatisticsDocument?: {},
};

export const patch = (contestId: uuid, body: PatchBody): Promise<any> => {
    return ApiConnection.patch('/contests/' + contestId, body);
};
