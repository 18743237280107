import Contest from "../model/Contest";
import * as apiClient from "../infrastructure/apiClient/apiClient";
import uuid from "../model/uuid";
import {normalizeContestForWriting} from "../model/ContestWithSchoolsAndClasses";
import ApiPromise from "../infrastructure/apiClient/ApiPromise";

const submitContest = (_contest: Contest): ApiPromise<uuid | void> => {
    let contest = {
        ..._contest
    };
    contest = normalizeContestForWriting(contest) as Contest;
    if (contest.id) {
        return ApiPromise.fromAxiosPromise(apiClient.contestsId.put(contest.id, contest))
            .onSuccess(() => {
                return;
            });
    }

    return ApiPromise.fromAxiosPromise(apiClient.contests.post(contest))
        .onSuccess((response: { data: { contestId: uuid } }) => {
            return response.data.contestId;
        });
};

export default submitContest;
