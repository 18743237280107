import {store} from "./redux/store";

class ProdContainer {
    protected store;

    public constructor() {
        this.store = store;
    }

    public getReduxStore = () => {
        return this.store;
    }
}

export default ProdContainer;
