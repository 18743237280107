import React from "react";
import SchoolWithClasses from "../../../model/SchoolWithClasses";
import uuid from "../../../model/uuid";
import SchoolSubSection from "./SchoolSubSection";

type State = {
    schools: Array<SchoolWithClasses>,
};

type Props = {
    contestId: uuid,
    schools: Array<SchoolWithClasses>
};

class SchoolsSection extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        const schools = props.schools;

        this.state = {
            schools: schools,
        }
    }

    render() {
        return (
            <div>
                <SchoolSubSection key={0} contestId={this.props.contestId} onSchoolCreated={this.onSchoolCreated}/>
                <h2 className='subtitle'>Dodane Szkoły</h2>
                {
                    this.state.schools.map((school, key) =>
                        <SchoolSubSection key={school.id} contestId={this.props.contestId} school={school}/>
                    )
                }

            </div>
        );
    }

    onSchoolCreated = (school: SchoolWithClasses) => {
        const schools = this.state.schools;
        schools.unshift(school);
        this.setState({schools});
    };
}

export default SchoolsSection;
