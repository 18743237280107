import React, {FormEvent} from "react";
import {connect} from "react-redux";
import reduxSignIn from "../../../redux/action/reduxSignIn";
import './style.scss'
import ApiPromise from "../../../infrastructure/apiClient/ApiPromise";
import ApiResponseStatus from "../../../infrastructure/apiClient/ApiResponseStatus";

type State = { username: string, password: string, error: string };
type Props = { signIn: (username: string, password: string) => ApiPromise<any, any> };

class SignInPage extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            error: ''
        };
    }

    render() {
        return (
            <div id="sign-in-page" className='page section'>
                <div id='sign-in-form-area'>
                    <div className='errors'>
                        {this.state.error}
                    </div>
                    <form id='sign-in-form' onSubmit={this.onSubmit}>
                        <label>Użytkownik</label>
                        <input id='input-username' type='text' className='field' value={this.state.username}
                               onChange={(e) => this.setState({username: e.target.value})}/>
                        <label>Hasło</label>
                        <input id='input-password' type='password' className='field' value={this.state.password}
                               onChange={(e) => this.setState({password: e.target.value})}/>
                        <button className='is-primary' type='submit'>Zaloguj</button>
                    </form>
                </div>
            </div>
        );
    }


    private onSubmit = (e: FormEvent) => {
        e.preventDefault();
        this.setState({error: ''});
        this.props.signIn(this.state.username, this.state.password)
            .onClientError((r, status) => {
                let msg = 'Nieoczekiwany błąd';
                switch (status) {
                    case ApiResponseStatus.INVALID_CREDENTIALS:
                        msg = 'Nieprawidłowy login lub hasło';
                }

                this.setState({error: msg});
            });
    };

}

export default connect(() => {
    return {}
}, {signIn: reduxSignIn})(SignInPage);
