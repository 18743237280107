import * as sessions from './endpoint/sessions';
import * as contests from './endpoint/contests';
import * as contestsId from './endpoint/contestsId';
import * as contestsIdSchools from './endpoint/contestsIdSchools';
import * as schoolsId from './endpoint/schoolsId';
import * as schoolsIdClasses from './endpoint/schoolsIdClasses';
import * as classesId from './endpoint/classesId';
import * as sponsorLogoFiles from './endpoint/sponsorLogoFiles';
import * as meSessions from './endpoint/meSessions';
import * as privacyPolicy from './endpoint/privacyPolicy';
import ApiPromise from "./ApiPromise";
import {AxiosResponse} from "axios";
import ApiResponseStatus from "./ApiResponseStatus";
import {reduxHandleUnauthorized} from "../../redux/action/reduxHandleUnauthorized";
import {reduxHandleServerError} from "../../redux/action/reduxHandleServerError";

ApiPromise.setDefaults({
    onClientError: (response: AxiosResponse, status: ApiResponseStatus) => {
        if (status === ApiResponseStatus.UNAUTHORIZED) {
            reduxHandleUnauthorized();
        }
        return response;
    },

    onServerError: (response: AxiosResponse, status: ApiResponseStatus) => {
        reduxHandleServerError();
        return response;
    }
});

export {
    sessions,
    contests,
    contestsId,
    contestsIdSchools,
    schoolsId,
    classesId,
    schoolsIdClasses,
    sponsorLogoFiles,
    meSessions,
    privacyPolicy
};
