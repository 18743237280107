import * as apiClient from "../infrastructure/apiClient/apiClient";
import Credentials from "../model/Credentials";
import ApiPromise from "../infrastructure/apiClient/ApiPromise";

const signIn = (username: string, password: string): ApiPromise<Credentials> => {
    return ApiPromise.fromAxiosPromise(apiClient.sessions.post({username, password}))
        .onSuccess((response) => {
            return response.data;
        });
};

export default signIn;

